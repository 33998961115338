
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
// const coupon = namespace("coupon");
const app = namespace("app");
@Component({})
export default class Footer extends Vue {
  // @coupon.State dialog;
  @app.State btnLoading;
  @Prop({ default: "返回" })
  leftText: string;
  @Prop({ default: "完成" })
  rightText: string;
  created() {}
  mounted() {}
  footerLeft() {
    this.$router.go(-1);
  }
  footerRight() {
    this.$emit("footerRight");
  }
}
